:root ._dsp-flex{display:flex;}
:root ._ai-center{align-items:center;}
:root ._fd-column{flex-direction:column;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._zi-1{z-index:1;}
:root ._pos-absolute{position:absolute;}
:root ._b-t-space-4{bottom:var(--t-space-4);}
:root ._r-t-space-4{right:var(--t-space-4);}
:root ._bg-foundation-90862117{background-color:var(--foundation--neutral--yang);}
:root ._jc-center{justify-content:center;}
:root ._pt-t-space-1{padding-top:var(--t-space-1);}
:root ._pr-t-space-1{padding-right:var(--t-space-1);}
:root ._pb-t-space-1{padding-bottom:var(--t-space-1);}
:root ._pl-t-space-1{padding-left:var(--t-space-1);}
:root ._btlr-t-radius-2{border-top-left-radius:var(--t-radius-2);}
:root ._btrr-t-radius-2{border-top-right-radius:var(--t-radius-2);}
:root ._bbrr-t-radius-2{border-bottom-right-radius:var(--t-radius-2);}
:root ._bblr-t-radius-2{border-bottom-left-radius:var(--t-radius-2);}
:root ._bxsh-0px4px8pxrg262875368{box-shadow:0px 4px 8px rgba(0,0,0,0.10196078431372549);}
:root ._ai-stretch{align-items:stretch;}
:root ._pos-relative{position:relative;}
:root ._h-96px{height:96px;}